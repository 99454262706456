import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-folio-info',
  templateUrl: './folio-info.component.html',
  styleUrls: ['./folio-info.component.scss'],
})
export class FolioInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
