import { Component, OnInit } from '@angular/core';
import { GenericService } from './../../services/generic.service';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  public ContrasenaTemporal: any = [];
  public  ContrasenaNuevo: any = [];
  public ContrasenaNuevo1: any = [];
  public ContrasenaIgual: boolean=false;
  public ContrasenaNueva: boolean=false;
  public Contrasena: any = [];



  constructor(
    private genericService: GenericService,
    private router: Router,
    
    ) { }

  ngOnInit() {}

  async reset(){

    let res = JSON.parse(localStorage.getItem("userSession"));
    this.Contrasena= this.ContrasenaNuevo.match(/\b^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,8}$\b/)
   if(this.Contrasena != null){
      console.log(this.Contrasena[0]);
      this.ContrasenaNuevo = this.Contrasena[0]
    console.log(res);
    let request: any = {
      usuarioid:1175,
      ContrasenaTemporal: this.ContrasenaTemporal,
      ContrasenaNuevo: this.ContrasenaNuevo,
          }
          if (this.ContrasenaTemporal === res.st_cveusuario ) {
            this.ContrasenaIgual=true;
            console.log('Contrasena Igual');
          } else{
            this.ContrasenaIgual=false;
            console.log('Contrasena diferente');
          }  

          if (this.ContrasenaNuevo === this.ContrasenaNuevo1 &&  this.ContrasenaNuevo!= this.ContrasenaTemporal) {
            this.ContrasenaNueva=true;
            console.log('Contrasena Igual');
          } else{
            this.ContrasenaNueva=false;
            console.log('Contrasena diferente');
          }  
          
          if( this.ContrasenaNueva===true && this.ContrasenaIgual===true){
            console.log('Actualizar Contrasena');
            let response = await this.genericService.sendPostRequest(environment.CambiarContrasena, request).toPromise();
            this.router.navigateByUrl('/main');
            let response2 =  this.genericService.sendPostRequest(environment.DatosUsuario, {id_usuario:res.id}).subscribe((response2: any) => {
              localStorage.setItem("userSession", JSON.stringify(response2[0]));
            });
          }
        }else{

          console.log("No cumple con COntrasena")
        }


  }

}
