
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenericService } from 'src/app/services/generic.service';
import { environment,twilio } from 'src/environments/environment.prod';
@Component({
  selector: 'app-verificacion',
  templateUrl: './verificacion.component.html',
  styleUrls: ['./verificacion.component.scss'],
})
export class VerificacionComponent implements OnInit {
  public IdCodigo: any = [];
  public Celular: any = [];
  public UsuariodatosVincular: any = [];
  public dataRenderUsuario: any = [
    {
      id: "inp-tel1",
      formControl: "tel1",
      label: "telefonoe",
      placeholder: "-",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },
    {
      id: "inp-tel2",
      formControl: "tel2",
      label: "telefonoe",
      placeholder: "-",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },
    {
      id: "inp-tel3",
      formControl: "tel3",
      label: "telefonoe",
      placeholder: "-",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },
    {
      id: "inp-tel4",
      formControl: "tel4",
      label: "telefonoe",
      placeholder: "-",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },
  
  
  
  ]
  constructor( private router: Router,
    private genericService: GenericService,) { }

  ngOnInit() {
    this.IdCodigo=localStorage.getItem("IdCodigo");
    this.Celular=localStorage.getItem("Celular");
    this.UsuariodatosVincular=localStorage.getItem("IdUsuario");

  }
  async goInicio(){
    var dataJson 
    let dataJsonV={}
   
      dataJson=this.dataRenderUsuario[0].defaultValue+this.dataRenderUsuario[1].defaultValue+this.dataRenderUsuario[2].defaultValue+this.dataRenderUsuario[3].defaultValue
      if(this.IdCodigo===dataJson){
        console.log("Usuario Validado")
        dataJsonV['telefono']=this.Celular;
        dataJsonV['usuarioid']=this.UsuariodatosVincular;
        console.log(dataJsonV);

       // dataJsonV['usuarioid']=this.datosVincular.usuarioid

      let response = await this.genericService.sendPostRequest(environment.vinculausuarioapp,dataJsonV).subscribe(res=>{ });
        this.router.navigateByUrl('/signIn');
      }
  
  console.log(dataJson);
 

  }

  back() {
    localStorage.setItem("IdCodigo",this.IdCodigo)
    window.history.back();
  }

  moveToNext(event,index) {
   // console.log(event.target)
    let a = document.getElementById("1");
    let b = document.getElementById("2");
    let c = document.getElementById("3");
    let d = document.getElementById("4");
    const  controls=[]

    let selected = document.getElementById(event.target.id)
    let next 
    if(selected===a){
    next=b
  //  console.log(next)
    if (this.dataRenderUsuario[0].defaultValue!=null) 
    next.focus(); 
    }
    if(selected===b){
      next=c
    //  console.log(next)  
      next.focus();
      }

      if(selected===c){
        next=d
      //  console.log(next)  
        next.focus();
        }
    

        if(event.key==="Backspace"){
          //console.log("Evento Correcto",event.key)
          this.dataRenderUsuario[0].defaultValue=null
          this.dataRenderUsuario[1].defaultValue=null
          this.dataRenderUsuario[2].defaultValue=null
          this.dataRenderUsuario[3].defaultValue=null
          let a = document.getElementById("1");
          a.focus();
        }


}


send(){
  const cel= this.Celular
  const numero=this.IdCodigo
  const accountSid = twilio.TWILIO_ACCOUNT_SID;
  const authToken = twilio.TWILIO_AUTH_TOKEN;
  const twilioNumber = twilio.TWILIO_PHONE_NUMBER;
  const myNumber = this.dataRenderUsuario[0].defaultValue;

  fetch('https://api.twilio.com/2010-04-01/Accounts/ACcbfe5be073fd43e30e186c476a05f026/Messages.json', {
    method: 'POST',
    headers: {
        'Authorization': 'Basic ' + btoa('ACcbfe5be073fd43e30e186c476a05f026:0f74546f763f625d6f111aa5b609a2d3')
    },
    body: new URLSearchParams({
        'MessagingServiceSid':'MG48ad506e4070362ef8a0ecc8a02b8912',
        'To': '+52'+cel,
       // 'From': '+17816507846',
        'Body': 'Mensaje Sms T-ilumina tu Numero de Registro es: '+numero
    })
    
});
}

BorrarInputs(event) {
 

  console.log(event.key)
  
   
}


  
}
