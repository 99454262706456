//export const path:string = "http://localhost/transfo-backend/educacion/";
//export const path:string = "http://localhost/api_educacion/educacion/";
//export const path:string = "http://localhost/connectandem/connectandem/";
export const path = "https://tecnoluminus.com.mx/wsx/appmovil.asmx/";

export const pathtadem = "https://api-connectandem.egasystems.com/connectandem/";
export const pathSettlementsCity = "https://olam-systems.com.mx/api_suite_home/settlements/getMunicipios";

export const emulado = true;

export const idEmpresa = 188;
export const catalogoSabias = 149;

export const remastered = false;

export const environment = { 
  production: true,
  //Services path
  genericQuerie: `${path}petition`,
  fcm: `${pathtadem}fcm-Tylumina`,// push notifications
  mail: `${path}email`,
  loadBlob:`${path}load-blob`,
  load:`${path}load`,
  loadPDF:`${path}load-pdf`,
  getImagenIndividual: `${path}image/`,
  getPDF: `${path}pdf/`,
  loadBlobOnly:`${path}load-blob-only`,
  loadFile:`${path}load-file`,
  loadFileOnly:`${path}load-only-file`,

  buscarfolio: `${path}ListadoSolicitudes`,
  catorigenapp: `${path}catorigenapp`,
  catmunicipioapp: `${path}catmunicipioapp`,
  catestadoapp: `${path}catestadoapp`,
  cattipoproblemaapp: `${path}cattipoproblemaapp`,
  guardaservicioapp: `${path}guardaservicioapp`,
  guardausuarioapp: `${path}guardausuarioapp`,
  vinculausuarioapp: `${path}vinculausuarioapp`,
  login: `${path}usuariologinapp`,
  DatosUsuario: `${path}DatosUsuario`,
  luminarias: `${path}ListadoLuminariasCercanas`,
  ListadoLuminarias: `${path}ListadoLuminarias`,
  CambiarContrasena: `${path}CambiarContrasena`,

};

export const firebaseConfig = {
  apiKey: "AIzaSyCUnpgutL4cCLb5gCPMsV28e0_unokHWL4",
  authDomain: "my-gym-v5.firebaseapp.com",
  projectId: "my-gym-v5",
  storageBucket: "my-gym-v5.appspot.com",
  messagingSenderId: "207458345350",
  appId: "1:207458345350:web:e9e17cb70b6d10cda64c07",
  measurementId: "G-VJX45VN69L"
};

export const twilio={
  TWILIO_ACCOUNT_SID:'ACcbfe5be073fd43e30e186c476a05f026',
  TWILIO_AUTH_TOKEN:'0f74546f763f625d6f111aa5b609a2d3',
  TWILIO_PHONE_NUMBER:'+17816507846',

}