import { map } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class GenericInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            map((resp: any) => {
                if (resp instanceof HttpResponse) {
                  //  console.log(resp);
                    if (resp.body.d) {
                        return resp.clone({
                            body: JSON.parse(resp.body.d)
                        });
                    } else {
                        return resp;
                    }
                }
                return resp;
            })
        );
    }

}