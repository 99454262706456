import { GenericService } from 'src/app/services/generic.service';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment.prod';


@Component({
  selector: 'app-sing-in',
  templateUrl: './sing-in.component.html',
  styleUrls: ['./sing-in.component.scss'],
})
export class SingInComponent implements OnInit {
  public cuenta: string = "";
  public contrasena: string = "";
  constructor(
    private alertService: AlertService,
    public router: Router,
    private dialog: MatDialog,
    private genericService: GenericService,
  ) 
  { }

  ngOnInit() {}

  async login(){
  if (this.cuenta.length <= 0 || this.contrasena.length <= 0) {
    this.alertService.errorAlert("Oops", "Asegurate de haber ingresado usuario y contraseña");
  }else {
    let request: any = {
      cuenta: this.cuenta,
      contrasena: this.contrasena
    }
    let response = await this.genericService.sendPostRequest(environment.login, request).subscribe((response: any) => {
      console.log(response[0].id);
      if (response[0].mensaje==="Bienvenido")
      {
        let response2 =  this.genericService.sendPostRequest(environment.DatosUsuario, {id_usuario:response[0].id}).subscribe((response2: any) => {
          localStorage.setItem("userSession", JSON.stringify(response2[0]));
          this.router.navigate(['/main']);
        });
     

      //localStorage.setItem("userSession", JSON.stringify(response[0]));
      }
      else{
        this.alertService.errorAlert("Oops", "Asegurate de haber ingresado usuario o contraseña correctos");

      }
    
      
    })}

}

goCrearcuenta(){
this.router.navigateByUrl('/registrar');
//console.log(this.contrasena.match(/\b^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,8}$\b/))


}

}
