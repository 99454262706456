import { LocalStorageEncryptService } from './../../services/local-storage-encrypt.service';
import { LoadingController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { GenericService } from 'src/app/services/generic.service';
import { environment } from 'src/environments/environment.prod';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { FCMData, FCMJson, FCMNotification } from 'src/app/services/fcm.service';

@Component({
  selector: 'app-historial-reportes',
  templateUrl: './historial-reportes.component.html',
  styleUrls: ['./historial-reportes.component.scss'],
})
export class HistorialReportesComponent implements OnInit {
  public reportes: any = [];
  public datosUsuario: any = [];
  public datoStorage: any = [];
  constructor(private genericService: GenericService,
    private localNotifications: LocalNotifications,
    private localStorageEncryptService: LocalStorageEncryptService
    ) { }

  ngOnInit() {
    var isAndroid
    this.mostrarUsario()
    this.listaReporte()
if(localStorage.getItem("reportes")){
    let r = JSON.parse(localStorage.getItem("reportes"));
    this.datoStorage=r
    console.log(this.datoStorage.Folio_serv);

    if(this.reportes.Folio_serv===this.datoStorage.Folio_serv ){
      console.log('notificacion');
      this.localNotifications.schedule({
        id: 1,
        text: 'Reportes Actualizados',
        sound: 'file://assets/alert.wav',
        //data: { secret: key }
      });

    }


}
    
  
  }



  async listaReporte(){
    let dataJson = {};
    dataJson['id_usuario'] =this.datosUsuario.id;
      let response: any = await this.genericService.sendPostRequest(environment.buscarfolio, dataJson).toPromise();
      response.forEach(element => {
        this.reportes.push({
          Folio_serv: element.Folio_serv,
          Direccion_Serv: element.Direccion_Serv,
          idestatus: element.EstatusServicio,
        });
      });    
      

console.log(this.reportes);


  }

mostrarUsario() {
  //this.api.get(this.url).subscribe((res) => {
   let res = JSON.parse(localStorage.getItem("userSession"));
   this.datosUsuario = res
  
  console.log(this.datosUsuario);
  // this.chang.detectChanges();
  //});

}
send() {
let token = this.localStorageEncryptService.getFromLocalStorage('token-t-ilumina');

  let notification: FCMNotification = {
    body: `Prueba`,
    title: `titulo`,
    click_action: "FCM_PLUGIN_ACTIVITY",
    image: null,
    color: "#F07C1B",
    "content-available": true
  };

  let dataFCM: FCMData = {
    body: `prueba`,
    title: `titulo`,
    view: 1,
    otherData: {

    }
  };

  let fcmd: FCMJson = {
    to: `${token}`,
    notification: notification,
    data: dataFCM,
    priority: "high"
  };

  this.genericService.sendPostRequest(environment.fcm, fcmd).subscribe((FCM) => {

  });
}

}
