import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'item-list-avatar',
  templateUrl: './item-list-avatar.html',
  styleUrls: ['./item-list-avatar.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class ItemListAvatarComponent implements OnDestroy, OnInit {

  @Input() data: any;
  
  constructor(
  ) {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {

  }

}
