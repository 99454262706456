import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-politica-de-privacidad',
  templateUrl: './politica-de-privacidad.component.html',
  styleUrls: ['./politica-de-privacidad.component.scss'],
})
export class PoliticaDePrivacidadComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {}


  goMain(){
    this.router.navigateByUrl('/main');
  }

}
