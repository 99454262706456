import { environment } from 'src/environments/environment.prod';
import { GenericService } from './../../services/generic.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme.service';




@Component({
  selector: 'app-cargando',
  templateUrl: './cargando.component.html',
  styleUrls: ['./cargando.component.scss'],
})

export class CargandoComponent implements OnInit, AfterViewInit {

  public images: any = [{
    url: "assets/imgs/onboarding/img.png",
    title: "Al usar T-ilumina *aceptas nuestros Terminosy Condiciones y nuestra Politica de Privacidad",
    subtitle: "Reporta Fallas de alumbrado publico de tu municipio en tiempo real."
  }, ];

  constructor(
    public themeService: ThemeService,
    private router: Router,
    private genericService: GenericService
  ) { }

  ngOnInit() { }
  ngAfterViewInit() {
    // const swiper = new Swiper(`.swiper-slideee`, {
    //   spaceBetween: 10,
    //   slidesPerView: 1,
    //   touchRatio: 0.2,
    //   loop: false,
    //   slideToClickedSlide: false,
    //   loopedSlides: 50,
    //   autoplay: false,
    //   direction: "horizontal",
    //   // If we need pagination
    //   pagination: {
    //     el: '.swiper-pagination',
    //     type: 'bullets',
    //   },
    // });
  }
  // goNext(){
  //   this.router.navigate(["login"]);
  // }

  goInicio() {
    // this.genericService.sendPostRequest(environment.catorigenapp, {}).subscribe((res: any) => {
    //   console.log(res);
    // });
    this.router.navigateByUrl('/signIn');
    //this.router.navigateByUrl('/main');
  }
  goRegistrar() {

    this.router.navigateByUrl('/registrar');

  }

}
