import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenericService } from 'src/app/services/generic.service';
import { environment,twilio } from 'src/environments/environment.prod';
import { Twilio } from "twilio";
import fetch from 'node-fetch';
@Component({
  selector: 'app-verificacion-cel',
  templateUrl: './verificacion-cel.component.html',
  styleUrls: ['./verificacion-cel.component.scss'],
})
export class VerificacionCelComponent implements OnInit {
  public IdUsuario: any = [];
  public dataRenderUsuario: any = [
    {
      id: "inp-telefono",
      formControl: "telefono",
      label: "telefonoe",
      placeholder: "+52 Número",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },]

  
  constructor( private router: Router,
               private genericService: GenericService,
    ) { }
 

  ngOnInit() {
    this.IdUsuario=localStorage.getItem("IdUsuario");
  
console.log(localStorage.getItem("IdUsuario"));
  }
  async goInicio(){
    let dataJson = {};
    
    this.dataRenderUsuario.forEach(element => {
        dataJson[element.formControl] = element.defaultValue;
        localStorage.setItem("Celular",element.defaultValue)
    });
      dataJson['usuarioid']=localStorage.getItem("IdUsuario");
      console.log(dataJson['telefono']);
      
      var val = Math.floor(1000 + Math.random() * 9000);
      console.log(val);
      
      localStorage.setItem("IdCodigo",val.toString())
     
        this.send(val,dataJson['telefono']);
        this.router.navigateByUrl('/verificar');
  }


send(numero,cel){
  const accountSid = twilio.TWILIO_ACCOUNT_SID;
  const authToken = twilio.TWILIO_AUTH_TOKEN;
  const twilioNumber = twilio.TWILIO_PHONE_NUMBER;
  const myNumber = this.dataRenderUsuario[0].defaultValue;

  fetch('https://api.twilio.com/2010-04-01/Accounts/ACcbfe5be073fd43e30e186c476a05f026/Messages.json', {
    method: 'POST',
    headers: {
        'Authorization': 'Basic ' + btoa('ACcbfe5be073fd43e30e186c476a05f026:0f74546f763f625d6f111aa5b609a2d3')
    },
    body: new URLSearchParams({
        'MessagingServiceSid':'MG48ad506e4070362ef8a0ecc8a02b8912',
        'To': '+52'+cel,
       // 'From': '+17816507846',
        'Body': 'Mensaje Sms T-ilumina tu Numero de Registro es: '+numero
    })
    
});

}

verificarleght(event:any){

  const btn = document.getElementById('button') as HTMLElement;

  if(event.length === 10){
    console.log('Cambio de Class',btn)
    btn.classList.remove('btn');
    btn.classList.add('btnverde');
    //document.getElementById('phone').classList.remove('btn')
    //document.getElementById('phone').classList.toggle('btnverde')


  }
  else{
    btn.classList.add('btn');
    btn.classList.remove('btnverde');

  }


}



}
