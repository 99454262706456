
import { MaterialModule } from 'src/app/material.module';

import { IonicModule } from '@ionic/angular';
import { ScrollingCardsComponent } from './components/scrolling-cards/scrolling-cards';
import { OpcionesComponent } from './components/opciones/opciones.component';
import { HeaderComponent } from './components/header/header';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { NgModule } from '@angular/core';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TabsComponent } from './components/tabs/tabs';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ContainerComponent } from './components/container/container';
import { ContainerAppComponent } from './components/container-app/container-app';
import { AdDirective } from './directives/ad.directive';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { InputFloatComponent } from './components/input-float/input-float';
import { ItemListAvatarComponent } from './components/item-list-avatar/item-list-avatar';
import { FabButton } from './components/fab-button/fab-button';
import { MapsComponent } from './components/maps/maps.component';
import { InputTandemComponent } from './components/input-tandem/input-tandem';
import { ButtonTandemComponent } from './components/button-tandem/button-tandem';
import { CargandoComponent } from './pages/cargando/cargando.component';
import { LoadComponent } from './pages/load/load.component';
import { SingInComponent } from './pages/sing-in/sing-in.component';
import { RegistrarComponent } from './pages/registrar/registrar.component';
import { VerificacionComponent } from './pages/verificacion/verificacion.component';
import { VerificacionCelComponent } from './pages/verificacion-cel/verificacion-cel.component';
import { SlidesComponent } from './pages/slides/slides.component';
import { MainComponent } from './pages/main/main.component';
import { HistorialReportesComponent } from './pages/historial-reportes/historial-reportes.component';
import { AcercaDeTIluminaComponent } from './pages/acerca-de-t-ilumina/acerca-de-t-ilumina.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { UserConfigComponent } from './pages/user-config/user-config.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { PoliticaDePrivacidadComponent } from './pages/politica-de-privacidad/politica-de-privacidad.component';
import { FolioInfoComponent } from './pages/folio-info/folio-info.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        FolioInfoComponent,
        PoliticaDePrivacidadComponent,
        ChangePasswordComponent,
        UserConfigComponent,
        ContactoComponent,
        AcercaDeTIluminaComponent,
        HistorialReportesComponent,
        MainComponent,
        SlidesComponent,
        VerificacionCelComponent,
        VerificacionComponent,
        RegistrarComponent,
        SingInComponent,
        LoadComponent,
        CargandoComponent,
        SpinnerOverlayComponent,
        SpinnerComponent,
        TabsComponent,
        HeaderComponent,
        ContainerComponent,
        ContainerAppComponent,
        OpcionesComponent,
        ScrollingCardsComponent,
        AdDirective, 
        InputFloatComponent,
        ItemListAvatarComponent,
        FabButton,
        MapsComponent,
        InputTandemComponent,
        ButtonTandemComponent
    ],
    exports:[
        FolioInfoComponent,
        PoliticaDePrivacidadComponent,
        ChangePasswordComponent,
        UserConfigComponent,
        ContactoComponent,
        AcercaDeTIluminaComponent,
        HistorialReportesComponent,
        MainComponent,
        SlidesComponent,
        VerificacionCelComponent,
        VerificacionComponent,
        RegistrarComponent,
        SingInComponent,
        LoadComponent,
        CargandoComponent,
        SpinnerOverlayComponent,
        SpinnerComponent,
        TabsComponent,
        HeaderComponent,
        ContainerComponent,
        ContainerAppComponent,
        OpcionesComponent,
        ScrollingCardsComponent,
        AdDirective,
        InputFloatComponent,
        ItemListAvatarComponent,
        FabButton,
        MapsComponent,
        InputTandemComponent,
        ButtonTandemComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule.forRoot(),
        MaterialModule,
        CKEditorModule,
        TranslateModule.forChild(
            {
                loader: {
                    provide: TranslateLoader,
                    useFactory: (createTranslateLoader),
                    deps: [HttpClient]
                }
            }
        ),
    ]
})
export class ComponentsModule { }
