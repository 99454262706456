import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-config',
  templateUrl: './user-config.component.html',
  styleUrls: ['./user-config.component.scss'],
})
export class UserConfigComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {}

  navigateInApp(url) {
    this.router.navigateByUrl(url);
  } 
}
