import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as leaflet from 'leaflet'
import { LoadingService } from 'src/app/services/loading.service';
import { GenericService } from './../../services/generic.service';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss'],
})
export class MapsComponent implements OnInit, AfterViewInit {
  public luminarias: any = [];
 
 
  public dataAdicional: any = {
    lat: 25.90499105,
    long: -100.199387371,
  }
  map: any;
  constructor(
    private loadingService: LoadingService,
    private genericService: GenericService,
  ) { }

  ngOnInit() {
    this.getEstados()
   }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.cargaMapa();
    }, 1000);
  }
  cargaMapa() {
    //this.loadingService.show();
    console.log("Se inicia la carga de mapa");
    var greenIcon = leaflet.icon({ iconUrl: 'assets/css/images/marker-icon-2x.png' })
    if (!this.dataAdicional.lat || this.dataAdicional.lat.length <= 0) {
      this.dataAdicional.lat = 20.9147916666667;
      this.dataAdicional.long = -100.87777;

    }
    this.map = leaflet.map('map').setView([this.dataAdicional.lat, this.dataAdicional.long], 16);

    let a: any = document.getElementsByClassName("leaflet-control-attribution");
    setTimeout(() => {
      a[0].children[0].style.display = "none";
    }, 500);
    // set map tiles source

    let osmLayer = new leaflet.TileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {

      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: 'Mapa de Connectandem &copy; <a href="https://www.google.com/">Connectandem</a>',
    });
    this.map.addLayer(osmLayer);

    let dataJson = {};
    dataJson['Latitud'] = '25.6663787';
    dataJson['Longitud'] = '-100.2712406';
    dataJson['metros'] = '2';
    console.log(dataJson);
    this.luminarias=  this.genericService.sendPostRequest(environment.luminarias, dataJson).subscribe((response: any) => {
      response.map(element => {
        leaflet.marker([element.Latitud, element.Longitud], { draggable: false, icon: greenIcon }).addTo(this.map);
        
        console.log('idLuminaria:',element.idLuminaria,'Latitud:',element.Latitud,'Longitud:',element.Longitud);
      });
      console.log(response.length)
    });

    
    

    // add marker to the map
   // let marker = leaflet.marker([this.dataAdicional.lat, this.dataAdicional.long], { draggable: true, icon: greenIcon }).addTo(this.map);
   // marker.on('drag', function (e) {
      console.log('marker drag event');
   // });
   // marker.on('dragstart', function (e) {
  //  });
   // marker.on('dragend', (e) => {
     // var marker = e.target;
    //  var position = marker.getLatLng();
     // this.dataAdicional.lat = position.lat;
      //this.dataAdicional.long = position.lng;
     // console.log(position);
     // marker.setLatLng(position, { draggable: 'true' }).bindPopup(position).update();
   // });

    this.loadingService.hide();
  }


  async getEstados() {
    

  }



}
