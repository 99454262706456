import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


@Component({
  selector: 'input-tandem',
  templateUrl: './input-tandem.html',
  styleUrls: ['./input-tandem.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class InputTandemComponent implements OnDestroy, OnInit {

  @Input() extraClass: string;
  @Input() id: string = "text";
  @Input() _id: string = "text";
  @Input() _idC: string = "text";
  @Input() inputType: string = "text";
  @Input() inputPlaceholder: string = "";
  @Input() inputMaxLength: number = 10;
  @Input() values: any[] = [];
  @Input() withAutocomplete: boolean = false;
  @Input() multipler: boolean = false;
  @Input() dato: any[] = [];
  @Input() label: string = '';

  @Input() selectWithImg: boolean = false;

  // Output prop name must be Input prop name + 'Change'
  // Use in your component to write an updated value back out to the parent
  @Input() tandemModel: string = "";
  @Output() tandemModelChange = new EventEmitter<any>();
  @Output() multipleSelectionChange = new EventEmitter<any>();
  @Output() realValueAutocomplete = new EventEmitter<any>();
  @Output() clickEvent = new EventEmitter<any>();
  public seleccion: any[] = [];
  public selected = -1;;
  public visto: boolean = false;   
  public filteredOptions: Observable<string[]>;
  public myControl = new FormControl();

  formControl = new FormControl();
  autoFilter: Observable<any[]>;

  private inputValue: any;
  public isArea: boolean = false;

  constructor(
  ) {
  }

  public ngOnInit(): void {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );

    if (this.inputType == "autoComplete") {
      this.autoFilter = this.formControl.valueChanges.pipe(
        startWith(''),
        map(value => this.mat_filter(value))
      );

      if (this.tandemModel?.length > 0) {
        this.mat_filter(this.tandemModel);
      }
    }

  }

  private mat_filter(value: any): any[] {
    try {

      const filterValue = value?.toLowerCase();
      //console.log(filterValue);


      let ele: any = this.values.filter(option => option.label.toLowerCase().includes(filterValue));
      try {
        if (ele.value) {
          this.tandemModelChange.emit(ele.value);
        }
      } catch (error) {

      }
      if (ele) {
        this.realValueAutocomplete.emit(ele);
      }
      return ele;
    } catch (error) {

    }
  }


  public ngOnDestroy(): void {

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.dato.filter(option => option.toLowerCase().includes(filterValue));
  }

  /*checkbox change event*/
  onChange(event) {
    console.log("-----------------");
    let index: any = this.seleccion.findIndex(x => x.value === event.value);
    if (index != -1) {
      console.log(index);
      if (this.seleccion.length == 1) {
        this.seleccion = [];
      } else {
        this.seleccion = this.seleccion.slice(index, this.seleccion.length);
      }
    } else {
      this.seleccion.push(event);
    }
    console.log(this.seleccion);


    this.multipleSelectionChange.emit(this.seleccion);
  }

  onKeyDown(evt: any) {
    var ASCIICode = (evt.which) ? evt.which : evt.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
      return false;
    return true;
  }

  Onclick(event:any){
    this.clickEvent.emit(event.target.innerText);

  }

  change(event:any){
    this.clickEvent.emit(event);
       if (this.visto==false) {
      this.visto = true;
      (document.getElementById('_id') as HTMLInputElement).type = "text";    
    } else {
      this.visto = false;
      (document.getElementById('_id') as HTMLInputElement).type ="password";


    }
    console.log((this.visto))
  }
  changeConfirmar(event:any){
    this.clickEvent.emit(event);
       if (this.visto==false) {
      this.visto = true;
      (document.getElementById('_idC') as HTMLInputElement).type = "text";    
    } else {
      this.visto = false;
      (document.getElementById('_idC') as HTMLInputElement).type ="password";


    }
    console.log((this.visto))
  }
}
