import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CargandoComponent } from './pages/cargando/cargando.component';
import { HistorialReportesComponent } from './pages/historial-reportes/historial-reportes.component';
import { AcercaDeTIluminaComponent } from './pages/acerca-de-t-ilumina/acerca-de-t-ilumina.component';

import { LoadComponent } from './pages/load/load.component';
import { MainComponent } from './pages/main/main.component';
import { RegistrarComponent } from './pages/registrar/registrar.component';
import { SingInComponent } from './pages/sing-in/sing-in.component';
import { SlidesComponent } from './pages/slides/slides.component';
import { VerificacionCelComponent } from './pages/verificacion-cel/verificacion-cel.component';
import { VerificacionComponent } from './pages/verificacion/verificacion.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { UserConfigComponent } from './pages/user-config/user-config.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { PoliticaDePrivacidadComponent } from './pages/politica-de-privacidad/politica-de-privacidad.component';
import { FolioInfoComponent } from './pages/folio-info/folio-info.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: "slides",
    pathMatch: "full"
  }, 
  {
    path: 'home',
    component: CargandoComponent,
  }, 
  {
    path: 'load',
    component: LoadComponent,
  }, 
  {
    path: 'signIn',
    component: SingInComponent,
  }, 
  {
    path: 'registrar',
    component: RegistrarComponent,
  }, 
  {
    path: 'verificar',
    component: VerificacionComponent,
  },
  {
    path: 'verificar-cel',
    component: VerificacionCelComponent,
  },
  {
    path: 'slides',
    component: SlidesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'historial-reportes',
    component: HistorialReportesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'acerca-tilumina',
    component: AcercaDeTIluminaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'contacto',
    component: ContactoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user-config',
    component: UserConfigComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'politica-de-privacidad',
    component: PoliticaDePrivacidadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'folio-info',
    component: FolioInfoComponent,
    canActivate: [AuthGuard],

  },
  
 
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
