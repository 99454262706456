import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acerca-de-t-ilumina',
  templateUrl: './acerca-de-t-ilumina.component.html',
  styleUrls: ['./acerca-de-t-ilumina.component.scss'],
})
export class AcercaDeTIluminaComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
