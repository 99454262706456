import { map } from 'rxjs/operators';
import { LoaderService } from './../../services/loading-service';
import { AlertService } from './../../services/alert.service';
import { emulado } from './../../../environments/environment.prod';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { GenericService } from './../../services/generic.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import * as leaflet from 'leaflet'


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  public datosIMunicipios: any[] = [];
  public datosUsuario: any = [];
  public fechaHoy: any = [];
  elementsToDesactivate: HTMLElement[];
  directionReportElements: HTMLElement[];

  direccionDeReporte: string = 'Dirección para reporte';
  direccionCompletaDeReporte: string = '';

  reportStatus: string = 'select-address';

  inputFolio: HTMLElement;
  changeAddressBtn: HTMLElement;
  mapBtnsSection: HTMLElement;
  nextBtn: HTMLElement;

  report = {
    problem: '',
    problemTime: ''
  };

  public edoSelected: any = null;

  public dataRender: any = [
    {
      id: "inp-folio",
      formControl: "inp-folio",
      label: "Nombre",
      placeholder: "NÚMERO DE FOLIO",
      required: true,
      defaultValue: null,
      type: "folio",
      withError: false
    },
    {
      id: "inp-direccion",
      formControl: "inp-direccion",
      label: "Direccion Luminaria",
      placeholder: "Direccion Luminaria",
      required: true,
      defaultValue: null,
      type: "address",
      withError: false
    },
    {
      id: "inp-municipio",
      formControl: "inp-municipio",
      label: "Direccion Luminaria",
      placeholder: "ELIGE EL MUNICIPIO",
      required: true,
      defaultValue: null,
      type: "autoComplete",
      withError: false
    },
   
  ];

  estados: any = [
    "Abasolo",
    "Agualeguas",
    "Allende",
    "Anahuac",
    "Apodaca",
    "Aramberri",
    "Bustamante",
    "Cadereyta Jimenez",
    "Cerralvo",
    "China",
    "Cienega de Flores",
    "Doctor Arroyo",
    "Doctor Coss",
    "Doctor Gonzalez",
    "El Carmen",
    "Galeana",
    "Garcia",
    "General Bravo",
    "General Escobedo",
    "General Teran",
    "General Trevino",
    "General Zaragoza",
    "General Zuazua",
    "Guadalupe",
    "Hidalgo",
    "Higueras",
    "Hualahuises",
    "Iturbide",
    "Juarez",
    "Lampazos de Naranjo",
    "Linares",
    "Los Aldamas",
    "Los Herreras",
    "Los Ramones",
    "Marin",
    "Melchor Ocampo",
    "Mier y Noriega",
    "Mina",
    "Montemorelos",
    "Monterrey",
    "Paras",
    "Pesqueria",
    "Rayones",
    "Sabinas Hidalgo",
    "Salinas Victoria",
    "San Nicolas de los Garza",
    "San Pedro Garza Garcia",
    "Santa Catarina",
    "Santiago",
    "Vallecillo",
    "Villaldama",
  ];
  public idlimunariasFolio: any = [];
  public adressLuminariaLog: any = [];
  public adressLuminariaLat: any = [];
  public municipios: any = [];
  public tipoProblemas: any = [];

  public searchSubscription: Subscription = null;
  public optionsLocation = {
    enableHighAccuracy: true,
    timeout: 30000,
    maximumAge: 60000
  };

  public coords: any = null;

  public reportData: any = {};
  public modalActiva: boolean = false;

  public origenes:any = null;

  public numReporte:any = "";
  public luminarias: any = [];
  public direccionGps: any = [];
  public direccionCompleta: any = [];
  public reportes: any = [];
  public dataAdicional: any = {
    lat: 25.90499105,
    long: -100.199387371,
  }
  map: any;

  constructor(
    
    private router: Router,
    private genericService: GenericService,
    private androidPermissions: AndroidPermissions,
    private alertService: AlertService,
    private loadingService: LoaderService,
    private geolocation: Geolocation) {
    if(this.idlimunariasFolio===null) {

      this.dataRender[0].defaultValue=this.idlimunariasFolio
      console.log(this.dataRender[0].defaultValue)
    } 
    this.getPosition();
    
  }

  ngOnInit() {
    this.init();
    
   
   
  }

  init() {
   // this.busquedaCordenadas();
    
   
   
    setTimeout(() => {
      this.elementsToDesactivate = [
        document.getElementById('header-btn'),
        document.getElementById('app-logo'),
        document.getElementById('map-btn'),
      ];

      this.directionReportElements = [
        document.getElementById('report-direction-input'),
        document.getElementById('direction-options'),
        document.getElementById('options-result'),
      ];

      this.nextBtn = document.getElementById('next-btn');
      this.mapBtnsSection = document.getElementById('mapButtons');
      this.inputFolio = document.getElementById('folio');
      this.changeAddressBtn = document.getElementById('changeAddressBtn');
    }, 500);

    this.getEstados();
    this.getOrigenes();
    this.mostrarUsario();

  }

  getPosition() {
    if (!emulado) {

      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(
        (result: any) => {
          if (result.hasPermission) {
            this.geolocation.getCurrentPosition().then((resp) => {
              // resp.coords.latitude
              console.log(this.coords);
              this.recargarcargaMapa( this.coords['latitude'], this.coords['longitude']);
              // resp.coords.longitude
              this.coords = resp.coords;
            }).catch((error) => {
              this.alertService.warnAlert("UBICACIÓN", "Para acceder a tu ubicación es necesario activar tu GPS");
            });
          } else {
            this.alertService.warnAlert("PERMISOS", "Para acceder a tu ubicación es necesario activar los permisos de Geolocalización en la app");
          }
        },
        err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
      );

      this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.CAMERA, this.androidPermissions.PERMISSION.GET_ACCOUNTS,this.androidPermissions.PERMISSION.ACCESS_MEDIA_LOCATION]);


    } else {
      navigator.geolocation.getCurrentPosition((position_any) => {
        this.coords = position_any.coords;
        console.log(this.coords);
        this.recargarcargaMapa( this.coords['latitude'], this.coords['longitude']);
        
      }, (error) => {
        console.log('Error getting location', error);
      }, this.optionsLocation);
    }


    

  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }


  async listaReporte(){
    let dataJson = {};
    dataJson['id_usuario'] =this.datosUsuario.id;
      let response: any = await this.genericService.sendPostRequest(environment.buscarfolio, dataJson).toPromise();
      response.forEach(element => {
        this.reportes.push({
          Folio_serv: element.Folio_serv,
          Direccion_Serv: element.Direccion_Serv,
          idestatus: element.EstatusServicio,
        });
      });    
      
      localStorage.setItem("reportes", JSON.stringify(this.reportes));
console.log(this.reportes);


  }



  async getEstados() {
    this.estados = await this.genericService.sendPostRequest(environment.catestadoapp, {}).toPromise();
    this.edoSelected = this.estados[0];
    this.getMunicipios();
  }

  async getOrigenes() {
    this.origenes = await this.genericService.sendPostRequest(environment.catorigenapp, {}).toPromise();
    //this.edoSelected = this.estados[0];
  }

  async getProblems() {
    this.tipoProblemas = await this.genericService.sendPostRequest(environment.cattipoproblemaapp, {}).toPromise();
    console.log(this.tipoProblemas);

  }

  async getMunicipios() {
    
    let response: any = await this.genericService.sendPostRequest(environment.catmunicipioapp, {
      intedo: this.edoSelected.id
    }).toPromise();

    response.map(element => {
      this.municipios.push({
        value: element.id,
        label: element.text
      });
    });
    //console.log(this.municipios);

  }

  clickOnReportDirection() {
    this.toggleActiveMainElements();
    this.toggleActiveReportElements();
  }

  toggleActiveMainElements() {
    this.elementsToDesactivate.forEach(element => {
      element.classList.toggle('disabled');
    });
  }

  toggleActiveReportElements() {
    this.directionReportElements.forEach(element => {
      element.classList.toggle('disabled');
    });
  }

  toggleMenu() {
    const menu = document.getElementById('menu');
    menu.classList.toggle('disabled');
  }

  navigateInApp(url) {
    this.router.navigateByUrl(url);

  }

  setDirection(direccion, extraString: string = "") {
    console.log(direccion);

    //this.toggleActiveReportElements();
    //this.toggleActiveMainElements();
    this.direccionDeReporte = direccion;
    this.reportData.direccion = `${direccion}, ${extraString}`;
    //this.disableFolioNumber();
    //this.changeAddressBtn.classList.remove('disabled');
   // this.mapBtnsSection.classList.toggle('map-btns__height');
  // this.nextBtn.classList.toggle('disabled');
  }

  disableFolioNumber() {
    if (this.direccionDeReporte !== 'Dirección para reporte') {
      this.inputFolio.classList.add('disabled');
    }
  }

  changeAddress() {
    this.inputFolio.classList.remove('disabled');
    this.changeAddressBtn.classList.add('disabled');
    this.mapBtnsSection.classList.toggle('map-btns__height');
    this.nextBtn.classList.toggle('disabled');
  }

  nextStep() {
    console.log((document.getElementById('auto') as HTMLInputElement).value)

if((document.getElementById('auto') as HTMLInputElement).value==='General Escobedo'){
  this.dataRender[2].defaultValue='ESCOBEDO'

}

if((document.getElementById('auto') as HTMLInputElement).value==='San Pedro Garza Garcia'){
  this.dataRender[2].defaultValue='SAN PEDRO (DEMO)'

}


console.log(this.dataRender[2].defaultValue);
    this.dataRender[0].defaultValue=(document.getElementById('inp-folio') as HTMLInputElement).value
    this.dataRender[1].defaultValue=(document.getElementById('id') as HTMLInputElement).value
    this.dataRender[2].defaultValue=(document.getElementById('auto') as HTMLInputElement).value
    this.setDirection(this.dataRender[1].defaultValue.toString())
    
   // console.log(this.adressLuminariaLat,this.adressLuminariaLog);
    switch (this.reportStatus) {
      case 'select-address':
        this.reportStatus = 'select-problem';
        break;

      case 'select-problem':
        this.reportStatus = 'select-time';
        break;

      case 'select-time':
        this.reportStatus = 'confirm-report';
        break;
    }

    this.printReportStep();
  }

  backStep() {
    console.log(this.reportStatus);

    switch (this.reportStatus) {
      case 'select-problem':
        this.reportStatus = 'select-address';
        break;

      case 'select-time':
        this.reportStatus = 'select-problem';
        break;

      case 'confirm-report':
        this.reportStatus = 'select-time';
    }
    this.printReportStep();
  }

  printReportStep() {
    const reportContainer = document.getElementById('reportContainer') as HTMLElement;

    const titleReport = document.getElementById('reportContainerTitle') as HTMLElement;
    const titleReportAddress = document.getElementById('reportContainerTitleAddress') as HTMLElement;
    const titleReportConfirm = document.getElementById('reportContainerTitleConfirm') as HTMLElement;
    const reportDescription = document.getElementById('reportDescription') as HTMLElement;

    const inputInfoSection = document.getElementById('inputInfo') as HTMLElement;
    const inputProblemSection = document.getElementById('inputProblem') as HTMLElement;
    const inputProblemTimeSection = document.getElementById('inputProblemTime') as HTMLElement;

    const confirmReportBtn = document.getElementById('confirmReport') as HTMLElement;

    switch (this.reportStatus) {
      case 'select-address':
        titleReport.classList.remove('disabled');
        titleReportAddress.classList.add('disabled');
        reportDescription.innerText = 'Selecciona tu municipio y coloca el pin en la ubicación de la luminaria e ingresa el folio o dirección para crear el reporte.';

        inputInfoSection.classList.remove('disabled');
        inputProblemSection.classList.add('disabled');
        this.changeAddressBtn.classList.remove('disabled');
        break;

      case 'select-problem':
        titleReport.classList.add('disabled');
        titleReportAddress.classList.remove('disabled');
        reportDescription.innerText = 'SELECCIONA EL PROBLEMA CON LA LUMINARIA:';

        inputInfoSection.classList.add('disabled');
        inputProblemSection.classList.remove('disabled');
        this.changeAddressBtn.classList.add('disabled');
        inputProblemTimeSection.classList.add('disabled');
        ///CONSUMO DE ENDPOINT DE PROBLEMAS

        this.getProblems()
       
        this.nextBtn.innerText = 'SIGUIENTE';
        break;

      case 'select-time':
        this.nextBtn.classList.remove('disabled');
        this.nextBtn.innerText = 'REPORTAR'
        inputInfoSection.classList.add('disabled');
        inputProblemSection.classList.add('disabled');
        inputProblemTimeSection.classList.remove('disabled');
        confirmReportBtn.classList.add('disabled');
        titleReportConfirm.classList.add('disabled');
        reportContainer.classList.remove('report-container__confirm');
        inputProblemTimeSection.classList.remove('disabled');
        titleReportAddress.classList.remove('disabled');
        reportDescription.classList.remove('disabled');
        this.mapBtnsSection.classList.remove('map-btns__height')
        break;

      case 'confirm-report':
        inputProblemTimeSection.classList.add('disabled');
        titleReportAddress.classList.add('disabled');
        titleReportConfirm.classList.remove('disabled');
        reportDescription.classList.add('disabled');

        confirmReportBtn.classList.remove('disabled');
        reportContainer.classList.add('report-container__confirm');
        this.mapBtnsSection.classList.add('map-btns__height')
        this.nextBtn.classList.add('disabled');
        break;
    }
  }

  setReportProblem(event: Event) {
    const element = event.target as HTMLElement;
    const btnsProblem = document.querySelectorAll('.btn-problem');

    btnsProblem.forEach(btn => {
      btn.classList.remove('info-btns__selected');
    });

    element.classList.add('info-btns__selected');

    this.report['problem'] = element.innerText;
    console.log(this.report);
    this.reportData.problema = this.report;
    
  }

  setReportProblemTime(event: Event) {
    const element = event.target as HTMLElement;
    const btnsProblem = document.querySelectorAll('.btn-problem-time');

    btnsProblem.forEach(btn => {
      btn.classList.remove('info-btns__selected');
    });

    element.classList.add('info-btns__selected');
    this.report['problemTime'] = element.innerText;
    //console.log(this.report);
    this.reportData.problema = this.report;
    //console.log(this.report.problem,"CON UN TIEMPO DE " + this.report.problemTime);

  }

  async reportToggle() {
    this.fechaHoy=new Date();
    
    if (this.coords != null) {
      const reportContainer = document.getElementById('reportContainer') as HTMLElement;
      const reportConfirmated = document.getElementById('reportConfirmated') as HTMLElement;

      reportContainer.classList.toggle('disabled');
      reportConfirmated.classList.toggle('disabled');
      let inputInfo = document.getElementById("inputInfo");
      inputInfo.classList.add("disabled");
      console.log(this.reportData);
      this.modalActiva = true;
      this.reportStatus = 'select-problem';
      this.loadingService.show();
      console.log(this.reportData.municipio.value) ;
      let request: any = {
        intedo: this.edoSelected.id,
       
        intmpo: this.reportData.municipio.value,
        intorigen: 8,
        sttelefono: this.datosUsuario.TelefonoApp,
        stfolioluminaria: this.dataRender[0].defaultValue,
        stdireccion: this.reportData.direccion,
        stentrecalle1: '',
        stentrecalle2: '',
        stcomentarios: this.report.problem+" CON UN TIEMPO DE "+this.report.problemTime,
        stnombrereporta: this.datosUsuario.st_nombre,
        stapellidopaterno: this.datosUsuario.st_paterno,
        stappellidomaterno: this.datosUsuario.st_materno,
        stemail: this.datosUsuario.st_email,
        intusuario: this.datosUsuario.id,
        stlat: this.coords.latitude,
        stlon: this.coords.longitude,
        inttiposervicio: 1,
        stcolonia: '',
        stfecha: this.fechaHoy,
        stcantlum: '1',
        stfolioexterno: ''
      };
      let response = await this.genericService.sendPostRequest(environment.guardaservicioapp, request).toPromise();
     console.log(response);
      this.numReporte = response[0].Folio;
      this.loadingService.hide();
      this.init();
    } else {
      this.getPosition();
    }
    this.listaReporte();
    
  }

  quitReport() {
    const reportContainer = document.getElementById('reportContainer') as HTMLElement;
    const reportConfirmated = document.getElementById('reportConfirmated') as HTMLElement;

    reportContainer.classList.remove('disabled');
    reportConfirmated.classList.add('disabled');
    this.modalActiva = false;

    this.reportStatus = 'select-report';
    this.reportStatus = 'confirm-report';
    this.backStep();
    this.backStep();
    this.backStep();
    this.changeAddress();
    //reportStatus: string = 'select-address';
    this.printReportStep();

  }
  onKeydown(){
    

  }

  municipiSelected(evt: any) {
   // console.log(evt);

  

   console.log(evt)
    try {
      if(evt.toUpperCase().trim()=='CIENEGA DE FLORES'){
        this.recargarcargaMapa(25.904442,-100.1992479);
    
       }
       if(evt.toUpperCase().trim()=='LOS RAMONES'){
        this.recargarcargaMapa(25.695897,-99.628331);
    
       }
       if(evt.toUpperCase().trim()=='CHINA'){
        this.recargarcargaMapa(25.70606759,-99.2522675);
    
       }
       if(evt.toUpperCase().trim()=='GENERAL BRAVO'){
        this.recargarcargaMapa(25.8260903,-99.0383309);
    
       }
       if(evt.toUpperCase().trim()=='SABINAS HIDALGO'){
        this.recargarcargaMapa(26.5123023,-100.1862655);
    
       }
       if(evt.toUpperCase().trim()=='LOS HERRERA'){
        this.recargarcargaMapa(25.955141,-99.4974848);
    
       }
       if(evt.toUpperCase().trim()=='SAN PEDRO (DEMO)'){
        this.recargarcargaMapa(25.6485765,-100.3346559);
    
       }
       if(evt.toUpperCase().trim()=='ESCOBEDO'){
        this.recargarcargaMapa(25.7988934,-100.339089);
    
       }

      let  a =this.municipios.find(e=>e.label === evt.toUpperCase().trim())
      console.log(a)
      this.reportData.municipio = a;
     
     

    } catch (error) {

    }
  }

  searchFolio(evt: any) {
    console.log(evt.target.value);
    this.reportData.folio = evt.target.value;

    // if (this.searchSubscription) {
    //   this.searchSubscription.unsubscribe();
    // }

    // this.searchSubscription =
    //   this.genericService.sendPostRequest(environment.buscarfolio, { folio: evt.target.value, idusuario: 1104 }).//cambiar id usuario
    //     subscribe((response: any) => {
    //       console.log(response);
    //       try {
    //         if (response.length > 0) {
    //           this.reportData.folio = evt.target.value;
    //         }
    //       } catch (error) {

    //       }
    //     }, (error: HttpErrorResponse) => {

    //     });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.cargaMapa(20.9147916666667,-100.87777);
    }, 1000);
  }
  cargaMapa(latitud,longitud) {
    //this.loadingService.show();
    console.log("Se inicia la carga de mapa");
    var greenIcon = leaflet.icon({ iconUrl: 'assets/css/images/marker-icon-2x.png' })
    if (!this.dataAdicional.lat || this.dataAdicional.lat.length <= 0) {
      this.dataAdicional.lat = latitud;
      this.dataAdicional.long = longitud ;

    }
    this.map = leaflet.map('map',{
      scrollWheelZoom: false,
      zoomSnap: 0.5,
      zoomDelta: 3,
      wheelPxPerZoomLevel: 12 
  }).setView([this.dataAdicional.lat, this.dataAdicional.long], 19);

    let a: any = document.getElementsByClassName("leaflet-control-attribution");
    setTimeout(() => {
      a[0].children[0].style.display = "none";
    }, 1500);
    let zom_in: any = document.getElementsByClassName("leaflet-control-zoom-in");
    let zom_out: any = document.getElementsByClassName("leaflet-control-zoom-out");
    setTimeout(() => {
      zom_in[0].addEventListener('click',(event)=>{
        console.log(event)

      });
      zom_out[0].addEventListener('click',(event)=>{
        console.log(event)

      });
    }, 1500);
    



    // set map tiles source

    let osmLayer = new leaflet.TileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      scrollWheelZoom: false,
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      //attribution: 'Mapa &copy; <a href="https://www.google.com/">Connectandem</a>',
    });
     this.map.zoomControl.setPosition('topleft');
    this.map.addLayer(osmLayer);

    let dataJson = {};
    dataJson['Latitud'] = this.dataAdicional.lat;
    dataJson['Longitud'] = this.dataAdicional.long;
    dataJson['metros'] = '2';



    // dataJson['intedo']=19
    // dataJson['intmpo']=2
    console.log(dataJson);
    this.luminarias=  this.genericService.sendPostRequest(environment.luminarias, dataJson).subscribe((response: any) => {
      response.map(element => {
        let marker =    leaflet.marker([element.latitud, element.longitud], { draggable: false, icon: greenIcon }).bindTooltip('Folio no.'+element.idLuminaria.toString()).openTooltip().addTo(this.map);
        const Http = new XMLHttpRequest();
        const adrees = 
        marker.on('click', function (e) {
          this.idlimunariasFolio=element.Folio;
          const url= 'https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat='+element.latitud+'&lon='+ element.longitud;
         //console.log(this.adressLuminariaLog);
         Http.open("GET", url);
         Http.send();
         Http.onreadystatechange = (e) => {
   const adrees = JSON.parse(Http.response)
   console.log(adrees.address.county
     );
     const removeAccents = (str) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 
   (document.getElementById('id') as HTMLInputElement).value = removeAccents(adrees.display_name);
   if(adrees.address.county){
   (document.getElementById('auto') as HTMLInputElement).value = removeAccents(adrees.address.county);
   }else{
    if(adrees.address.town){
    (document.getElementById('auto') as HTMLInputElement).value = removeAccents(adrees.address.town);
    }
    else
    (document.getElementById('auto') as HTMLInputElement).value = removeAccents(adrees.address.city);
        }
   
         
      
 }
          document.getElementById('changeAddressBtn').classList.remove('disabled');
          document.getElementById('mapButtons').classList.toggle('map-btns__height');
          document.getElementById('next-btn').classList.toggle('disabled');
          (document.getElementById('inp-folio') as HTMLInputElement).value = element.Folio;
          (document.getElementById('id') as HTMLInputElement).value = this.adressLuminariaLog;
          document.getElementById('inp-folio').style.textTransform='uppercase';
          document.getElementById('id').style.textTransform='uppercase';
          document.getElementById('auto').style.textTransform='uppercase';
          
         });
         
        // console.log('idLuminaria:',element.idLuminaria,'Latitud:',element.Latitud,'Longitud:',element.Longitud);
       });
       //console.log(response.length)
     });
 
    

    // add marker to the map
   // let marker = leaflet.marker([this.dataAdicional.lat, this.dataAdicional.long], { draggable: true, icon: greenIcon }).addTo(this.map);
   // marker.on('drag', function (e) {
      console.log('marker drag event');
   // });
   // marker.on('dragstart', function (e) {
  //  });
   // marker.on('dragend', (e) => {
     // var marker = e.target;
    //  var position = marker.getLatLng();
     // this.dataAdicional.lat = position.lat;
      //this.dataAdicional.long = position.lng;
     // console.log(position);
     // marker.setLatLng(position, { draggable: 'true' }).bindPopup(position).update();
   // });
  
    this.loadingService.hide();
  }

  recargarcargaMapa(latitud,longitud) {
    
    console.log(longitud,latitud);
    this.map.remove();
    var greenIcon = leaflet.icon({ iconUrl: 'assets/css/images/marker-icon-2x.png' })
    var redIcon = leaflet.icon({ iconUrl: 'assets/css/images/marker-icon-red.png' })
    
    this.map = leaflet.map('map',{
      scrollWheelZoom: false,
      zoomSnap: 1.9,
  }).setView([ latitud,longitud], 15);

    let a: any = document.getElementsByClassName("leaflet-control-attribution");
    setTimeout(() => {
      a[0].children[0].style.display = "none";
    }, 2000);
    // set map tiles source
    let zom_in: any = document.getElementsByClassName("leaflet-control-zoom-in");
    let zom_out: any = document.getElementsByClassName("leaflet-control-zoom-out");
    setTimeout(() => {
      zom_in[0].addEventListener('click',(event)=>{
        console.log(event)

      });
      zom_out[0].addEventListener('click',(event)=>{
        console.log(event)

      });
    }, 2000);
    

    let osmLayer = new leaflet.TileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      //attribution: 'Mapa de Connectandem &copy; <a href="https://www.google.com/">Connectandem</a>',
    });
   

    
    this.map.addLayer(osmLayer);
    this.map.zoomControl.setPosition('topleft');
    let dataJson = {};
    dataJson['Latitud'] = latitud;
    dataJson['Longitud'] = longitud;
     dataJson['metros'] = '2';
    // dataJson['intedo']=19
    // dataJson['intmpo']=2
    
    
    this.luminarias=  this.genericService.sendPostRequest(environment.luminarias, dataJson).subscribe((response: any) => {
      response.map(element => {
       // 
        
        let marker =    leaflet.marker([element.latitud, element.longitud], { click: true, icon: greenIcon }).bindTooltip('Folio Poste:'+element.Folio.toString()).openTooltip().addTo(this.map);
        const Http = new XMLHttpRequest();
        marker.on('click', function (e) {
          this.idlimunariasFolio=element.Folio;
          const url= 'https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat='+element.latitud+'&lon='+ element.longitud;
         //console.log(this.adressLuminariaLog);
         Http.open("GET", url);
         Http.send();
         Http.onreadystatechange = (e) => {
        const adrees = JSON.parse(Http.response)
        console.log(adrees.address.county
     );
     const removeAccents = (str) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 
   (document.getElementById('id') as HTMLInputElement).value = removeAccents(adrees.display_name);
   if(adrees.address.county){
   (document.getElementById('auto') as HTMLInputElement).value = removeAccents(adrees.address.county);
   }else{
    if(adrees.address.town){
    (document.getElementById('auto') as HTMLInputElement).value = removeAccents(adrees.address.town);
    }
    else
    (document.getElementById('auto') as HTMLInputElement).value = removeAccents(adrees.address.city);
        }
   
         
      
 }
          document.getElementById('changeAddressBtn').classList.remove('disabled');
          document.getElementById('mapButtons').classList.toggle('map-btns__height');
          document.getElementById('next-btn').classList.toggle('disabled');
          (document.getElementById('inp-folio') as HTMLInputElement).value = element.Folio;
          (document.getElementById('id') as HTMLInputElement).value = this.adressLuminariaLog;
          document.getElementById('inp-folio').style.textTransform='uppercase';
          document.getElementById('id').style.textTransform='uppercase';
          document.getElementById('auto').style.textTransform='uppercase';
          let i:HTMLElement = document.getElementById('mat-option#option') as HTMLElement;
          console.log(i)
         });
         
        // console.log('idLuminaria:',element.idLuminaria,'Latitud:',element.Latitud,'Longitud:',element.Longitud);
       });
       //console.log(response.length)
     });
 
    
    let marker = leaflet.marker([latitud, longitud], { draggable: true, icon: redIcon }).addTo(this.map);
     marker.on('dragstart', function (e) {
    });
   marker.on('dragend', (e) => {
    
      var marker = e.target;
      var position = marker.getLatLng();
      latitud= position.lat;
      longitud= position.lng;
      console.log(position);
      this.map.remove();
      var greenIcon = leaflet.icon({ iconUrl: 'assets/css/images/marker-icon-2x.png' })
      var redIcon = leaflet.icon({ iconUrl: 'assets/css/images/marker-icon-red.png' })
      if (!this.dataAdicional.lat || this.dataAdicional.lat.length <= 0) {
        this.dataAdicional.lat = latitud;
        this.dataAdicional.long = longitud;
      //  [this.coords['latitude'], this.coords['longitude']
      }
      this.map = leaflet.map('map',{
        scrollWheelZoom: false,
      zoomSnap: 1.9,
    }).setView([position.lat, position.lng], 15);
  
      let a: any = document.getElementsByClassName("leaflet-control-attribution");
      setTimeout(() => {
        a[0].children[0].style.display = "none";
      }, 1500);
      // set map tiles source
      setTimeout(() => {
        zom_in[0].addEventListener('click',(event)=>{
          //console.log(event)
  
        });
        zom_out[0].addEventListener('click',(event)=>{
          //console.log(event)
  
        });
      }, 2500);
  
      let osmLayer = new leaflet.TileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution: 'Mapa de Connectandem &copy; <a href="https://www.google.com/">Connectandem</a>',
      });
     
  
      
      this.map.addLayer(osmLayer);
      this.map.zoomControl.setPosition('topleft');

      let dataJson = {};
      
      dataJson['Latitud'] = position.lat;
      dataJson['Longitud'] = position.lng;
       dataJson['metros'] = '2';
      this.luminarias=  this.genericService.sendPostRequest(environment.luminarias, dataJson).subscribe((response: any) => {
        response.map(element => {
         // 
          
          let marker =    leaflet.marker([element.latitud, element.longitud], { click: true, icon: greenIcon }).bindTooltip('Folio Poste:'+element.Folio.toString()).openTooltip().addTo(this.map);
          const Http = new XMLHttpRequest();
          marker.on('click', function (e) {
            this.idlimunariasFolio=element.Folio;
            const url= 'https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat='+element.latitud+'&lon='+ element.longitud;
           //console.log(this.adressLuminariaLog);
           Http.open("GET", url);
           Http.send();
           Http.onreadystatechange = (e) => {
          const adrees = JSON.parse(Http.response)
          console.log(adrees.address.county
       );
       const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      } 
     (document.getElementById('id') as HTMLInputElement).value = removeAccents(adrees.display_name);
     if(adrees.address.county){
     (document.getElementById('auto') as HTMLInputElement).value = removeAccents(adrees.address.county);
     }else{
      if(adrees.address.town){
      (document.getElementById('auto') as HTMLInputElement).value = removeAccents(adrees.address.town);
      }
      else
      (document.getElementById('auto') as HTMLInputElement).value = removeAccents(adrees.address.city);
          }
     
           
        
   }
            document.getElementById('changeAddressBtn').classList.remove('disabled');
            document.getElementById('mapButtons').classList.toggle('map-btns__height');
            document.getElementById('next-btn').classList.toggle('disabled');
            (document.getElementById('inp-folio') as HTMLInputElement).value = element.Folio;
            (document.getElementById('id') as HTMLInputElement).value = this.adressLuminariaLog;
            document.getElementById('inp-folio').style.textTransform='uppercase';
            document.getElementById('id').style.textTransform='uppercase';
            document.getElementById('auto').style.textTransform='uppercase';
           });
           
          // console.log('idLuminaria:',element.idLuminaria,'Latitud:',element.Latitud,'Longitud:',element.Longitud);
         });
         //console.log(response.length)
       });
       marker = leaflet.marker([latitud, longitud], { draggable: false, icon: redIcon }).addTo(this.map);
     marker.setLatLng(position, { draggable: 'true' }).bindPopup(position).update();
   });
    this.loadingService.hide();
   
    

    

  }


  mostrarUsario() {
    //this.api.get(this.url).subscribe((res) => {
     let res = JSON.parse(localStorage.getItem("userSession"));
     this.datosUsuario = res
    
    console.log(this.datosUsuario);
    // this.chang.detectChanges();
    //});
  
  }

mostrarDirecciones(latitud,longitud){
  let response =  this.genericService.sendGetParams(
    
    
    'https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat='+latitud+'&lon='+longitud, {}).subscribe((response: any)=> {
      this.direccionGps=response.address
      this.direccionCompleta=response.display_name
    });
 

}

cerrarSession(){

  localStorage.removeItem("userSession");
  this.router.navigate(['/']);
}

recursivo(arreglo:any[],index){
if(arreglo[index]){

  this.genericService.sendPostRequest(environment.ListadoLuminarias, {
    intedo: 19,
    intmpo:arreglo[index].id}).subscribe((res:any)=>{
      index++
      console.log(arreglo);
      console.log(res);
      if(res[0]){this.datosIMunicipios.push({...arreglo[index-1],...res[0]})}
      this.recursivo(arreglo,index)

    },(error:HttpErrorResponse)=>{
      index++
      this.recursivo(arreglo,index)
    });


}else{

  this.next();
}

}

async busquedaCordenadas(){
  let response: any = await this.genericService.sendPostRequest(environment.catmunicipioapp, {
    intedo: '19'
  }).toPromise();
    
  
  let i : number=0
  this.recursivo(response,i);
    
 console.log(this.datosIMunicipios[0].text);

}


next(){
  this.datosIMunicipios.map(e=>{
console.log(e.text, e.longitud, e.latitud)


  })
  console.log(this.datosIMunicipios);

}



}
