import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.scss'],
})
export class LoadComponent implements OnInit {

  public images: any = [{
    url: "assets/imgs/onboarding/img.png",
    title: "Al usar T-ilumina *aceptas nuestros Terminosy Condiciones y nuestra Politica de Privacidad",
    subtitle: "Reporta Fallas de alumbrado publico de tu municipio en tiempo real."
  }, {
    url: "assets/imgs/onboarding/img.png",
    title: "Continue to improve your language skills with ConnecTandem",
    subtitle: "Al usar T-ilumina *aceptas nuestros Terminosy Condiciones y nuestra Politica de Privacidad"
  }];
  constructor() { }

  ngOnInit() {}

}
