import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenericService } from 'src/app/services/generic.service';
import { environment } from 'src/environments/environment.prod';
import { AlertService } from '../../services/alert.service';
@Component({
  selector: 'app-registrar',
  templateUrl: './registrar.component.html',
  styleUrls: ['./registrar.component.scss'],
})
export class RegistrarComponent implements OnInit {
  public Contrasena: any = [];

  public dataRenderUsuario: any = [
    {
      id: "inp-nombre",
      formControl: "nombre",
      label: "Nombre",
      placeholder: "Nombre(s)",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },
    {
      id: "inp-apellidopaterno",
      formControl: "apellidopaterno",
      label: "Apellido Paterno",
      placeholder: "Apellido Paterno",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },
    {
      id: "inp-apellidomaterno",
      formControl: "apellidomaterno",
      label: "Apellido Paterno",
      placeholder: "Apellido Materno",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },
    {
      id: "inp-username",
      formControl: "username",
      label: "Email",
      placeholder: "Email",
      required: true,
      defaultValue: null,
      type: "Email",
      withError: false
    },
    {
      id: "inp-contrasena",
      formControl: "contrasena",
      label: "Contraseña",
      placeholder: "Contraseña",
      required: true,
      defaultValue: null,
      type: "contrasena",
      withError: false
    },
    {
      id: "inp-confirContrasena",
      formControl: "contrasena2",
      label: "Contraseña",
      placeholder: "Confirmar Contraseña",
      required: true,
      defaultValue: null,
      type: "contrasenaConfirmar",
      withError: false
    },
    
    


    
  ];

  constructor( private router: Router,
              private genericService: GenericService,
              private alertService: AlertService,
    
    
    ) { }

  ngOnInit() {}
  goInicio(){

    this.router.navigateByUrl('/verificar-cel');

  }


  async GuardarUsuario(){
    this.Contrasena= this.dataRenderUsuario[4].defaultValue.match(/\b^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,8}$\b/)

    
    if(this.Contrasena != null && this.dataRenderUsuario[2].defaultValue!=null && this.dataRenderUsuario[1].defaultValue!=null && this.dataRenderUsuario[0].defaultValue!=null && this.Contrasena[0]===this.dataRenderUsuario[5].defaultValue){
    let dataJson = {};
    this.dataRenderUsuario.forEach(element => {
      
        dataJson[element.formControl] = element.defaultValue;
    });
    dataJson['mail']=this.dataRenderUsuario[3].defaultValue
    console.log(dataJson);
    let response = await this.genericService.sendPostRequest(environment.guardausuarioapp, dataJson).subscribe(res=>{
      localStorage.setItem("IdUsuario",res[0].Folio)
    this.router.navigateByUrl('/verificar-cel');

    });
  }
  else{
    this.alertService.errorAlert("Oops", "La contraseña debe contener una letra  mayúscula, una letra minúscula, un número y contener de 4 a 8 caracteres.");
    console.log('Faltan Campos o Contrasenano No Cumple')
    console.log(this.Contrasena)
  }
  }
signIn(){
  this.router.navigateByUrl('/signIn');
}

  
}
